import React, { useState, useEffect } from 'react';
import { Cloud, Sparkles } from 'lucide-react';

const NotFound = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: (e.clientX / window.innerWidth) * 2 - 1,
        y: (e.clientY / window.innerHeight) * 2 - 1
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className="min-h-screen relative overflow-hidden bg-[#020314]">
      {/* Magical background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Animated gradient orbs */}
        <div 
          className="absolute w-[800px] h-[800px] rounded-full blur-3xl opacity-30 animate-float"
          style={{
            background: 'linear-gradient(45deg, rgba(138, 43, 226, 0.4), rgba(0, 191, 255, 0.4))',
            transform: `translate(${mousePosition.x * 20}px, ${mousePosition.y * 20}px)`,
            top: '-20%',
            right: '-10%',
          }}
        />
        <div 
          className="absolute w-[600px] h-[600px] rounded-full blur-3xl opacity-20 animate-float-reverse"
          style={{
            background: 'linear-gradient(45deg, rgba(255, 0, 255, 0.3), rgba(0, 255, 255, 0.3))',
            transform: `translate(${mousePosition.x * -20}px, ${mousePosition.y * -20}px)`,
            bottom: '-10%',
            left: '-10%',
          }}
        />
        
        {/* Shimmering overlay */}
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ0cmFuc3BhcmVudCIvPjxwYXRoIGQ9Ik0wIDAgTDIwMCAyMDBNMjAwIDAgTDAgMjAwIiBzdHJva2U9InJnYmEoMjU1LDI1NSwyNTUsMC4wNSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==')] opacity-20 animate-shimmer"></div>
        
        {/* Floating particles */}
        <div className="absolute inset-0">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 bg-white rounded-full animate-float-particle"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                opacity: 0.2
              }}
            />
          ))}
        </div>
      </div>

      <div className="relative flex flex-col items-center justify-center px-3 sm:px-4 min-h-screen py-12 sm:py-0">
        <div className="text-center w-full max-w-3xl mx-auto backdrop-blur-sm rounded-xl p-3 sm:p-8">
          {/* Logo */}
          <div className="flex justify-center items-center gap-2 mb-6 sm:mb-8 group">
            <div className="relative transform hover:scale-110 transition-all duration-300">
              <Cloud className="h-7 w-7 sm:h-8 sm:w-8 text-cyan-300" />
              <div className="absolute inset-0 animate-ping opacity-50">
                <Cloud className="h-7 w-7 sm:h-8 sm:w-8 text-cyan-300" />
              </div>
              <Sparkles className="absolute -top-2 -right-2 h-3 w-3 sm:h-4 sm:w-4 text-yellow-300 animate-pulse" />
            </div>
            <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-cyan-300 via-purple-300 to-pink-300 bg-clip-text text-transparent drop-shadow-[0_0_15px_rgba(59,130,246,0.5)]">
              TinyCloud
            </span>
          </div>

          {/* 404 Message */}
          <h1 className="text-4xl sm:text-6xl font-bold mb-6 sm:mb-8 leading-tight tracking-tight px-2">
            404 - Page Not Found
          </h1>

          <p className="font-space-grotesk text-base sm:text-xl md:text-2xl text-blue-100 mb-4 sm:mb-6 max-w-2xl mx-auto leading-snug sm:leading-relaxed px-2 sm:px-4">
            The page you are looking for does not exist.
          </p>

          <a href="/" className="px-4 py-2 bg-cyan-400 rounded-lg text-white font-medium hover:bg-cyan-500 transition">
            Go Back Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;