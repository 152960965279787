import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TinyCloudLanding from './TinyCloudLanding';
import MuseLanding from './MuseLanding';
import MarkdownPage from './MarkdownPage';
import NotFound from './NotFound';
import Redirect from './Redirect';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TinyCloudLanding />} />
        <Route path="/muse" element={<MuseLanding />} />
        <Route path="/privacy" element={<MarkdownPage name="Privacy" />} />
        <Route path="/delete-user" element={<MarkdownPage name="DeleteUser" />} />
        <Route path="/ios" element={<Redirect />} />
        <Route path="/android" element={<Redirect />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;