import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownPage = ({ contentPath }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(`/content/${contentPath}`)
      .then(response => response.text())
      .then(text => setContent(text))
      .catch(error => console.error('Error loading markdown:', error));
  }, [contentPath]);

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownPage;