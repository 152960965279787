import React from 'react';
import { Mic, Brain, ArrowRight, Sparkles, BookOpen, Network, Lock } from 'lucide-react';
import logoIcon from './assets/logoIcon.png';
import textLogo from './assets/Text-Logo.png';
import MuseIconWeb from './assets/MuseIconWeb.png';

const MuseLanding = () => {
  return (
    <div className="min-h-screen relative overflow-hidden bg-[#4573B9]">
      {/* Background gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#282F4B] via-[#4573B9] to-[#4573B9] opacity-90" />

      <div className="relative flex flex-col items-center justify-center px-3 sm:px-4 min-h-screen py-12 sm:py-0">
        <div className="text-center w-full max-w-3xl mx-auto backdrop-blur-sm rounded-xl p-3 sm:p-8 bg-[#282F4B]/10">
          {/* Logo Section - reduced bottom margin */}
          <div className="flex justify-center items-center gap-2 mb-4 sm:mb-6 group">
            <div className="relative transform hover:scale-110 transition-all duration-300">
              <img 
                src={logoIcon} 
                alt="TinyCloud Icon"
                className="h-8 w-8 sm:h-10 sm:w-10 object-contain"
              />
            </div>
            <img 
              src={textLogo}
              alt="TinyCloud"
              className="h-5 sm:h-6 object-contain"
            />
          </div>

          {/* App Icon - increased size and margins */}
          <div className="flex justify-center mb-8 sm:mb-10">
            <img 
              src={MuseIconWeb} 
              alt="Muse AI"
              className="h-24 w-24 sm:h-32 sm:w-32 md:h-40 md:w-40 object-contain rounded-3xl"
            />
          </div>

          {/* Tagline */}
          <p className="font-space-grotesk text-xl sm:text-2xl md:text-3xl text-[#FDF9D2] mb-4 sm:mb-5 max-w-2xl mx-auto leading-snug sm:leading-relaxed px-2 sm:px-4 font-medium">
            Conversations flow. Intelligence grows.
          </p>
          
          {/* Main Value Proposition */}
          <p className="font-space-grotesk text-base sm:text-lg md:text-xl text-[#FDF9D2]/80 mb-12 sm:mb-14 max-w-2xl mx-auto leading-relaxed px-4 sm:px-6">
            Transform your conversations into actionable knowledge. Muse AI captures and transcribes your in-person discussions while letting you focus on what matters most - the conversation itself.
          </p>

          {/* Download Buttons */}
          <div className="w-full max-w-xl mx-auto mb-16 sm:mb-20 px-2">
            <div className="flex flex-col sm:flex-row gap-5 justify-center">
              <a 
                href="/ios" 
                className="w-full sm:w-auto px-8 py-4 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg"
              >
                <Sparkles className="h-5 w-5" />
                Download for iOS
                <ArrowRight className="h-5 w-5" />
              </a>
              <a 
                href="/android" 
                className="w-full sm:w-auto px-8 py-4 bg-[#7DB0D2] hover:bg-[#7DB0D2]/90 text-[#282F4B] font-bold rounded-lg flex items-center justify-center gap-3 transition-all hover:scale-105 text-lg"
              >
                <Sparkles className="h-5 w-5" />
                Download for Android
                <ArrowRight className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Features Grid - increased padding and gaps */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-10 mb-16 sm:mb-20 px-4 sm:px-6">
            {[
              { 
                icon: Mic, 
                title: "Intelligent Capture", 
                desc: "Real-time transcription with contextual note-taking. Focus on the conversation, not documentation." 
              },
              { 
                icon: Network, 
                title: "Knowledge That Grows", 
                desc: "Connect insights across discussions and build your personal knowledge base." 
              },
              { 
                icon: Brain, 
                title: "AI-Powered Understanding", 
                desc: "Generate summaries, find connections, and surface relevant insights when needed." 
              }
            ].map(({ icon: Icon, title, desc }) => (
              <div key={title} className="flex flex-col items-center group p-6 sm:p-8 bg-[#282F4B]/10 rounded-lg backdrop-blur-sm border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 hover:bg-[#282F4B]/20 transition-all duration-300">
                <div className="relative mb-4 sm:mb-5">
                  <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-[#7DB0D2]" />
                  <div className="absolute inset-0 animate-ping opacity-50 group-hover:opacity-100">
                    <Icon className="h-6 w-6 sm:h-8 sm:w-8 text-[#7DB0D2]" />
                  </div>
                </div>
                <h3 className="font-medium text-[#FDF9D2] mb-3 text-lg sm:text-xl">{title}</h3>
                <p className="text-sm sm:text-base text-[#FDF9D2]/80 text-center leading-relaxed">
                  {desc}
                </p>
              </div>
            ))}
          </div>

          {/* Future Vision Section */}
          <div className="text-center mb-16 sm:mb-20 px-4">
            <h2 className="text-2xl sm:text-3xl font-bold text-[#FDF9D2] mb-3">
              Future Vision
            </h2>
            <p className="text-[#FDF9D2]/70 mb-10 text-sm sm:text-base">
              A glimpse into what's coming next
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-2xl mx-auto">
              <div className="bg-gradient-to-br from-[#282F4B]/40 to-[#4573B9]/20 backdrop-blur-sm rounded-xl p-6 border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 transition-all duration-500 group hover:shadow-lg hover:shadow-[#7DB0D2]/10">
                <h3 className="text-[#FDF9D2] text-lg font-semibold tracking-wide mb-4">
                  Voice Prompting
                </h3>
                <p className="text-[#FDF9D2]/80 text-sm sm:text-base text-center leading-relaxed">
                  Interactive note-taking through natural conversation
                </p>
              </div>

              <div className="bg-gradient-to-br from-[#282F4B]/40 to-[#4573B9]/20 backdrop-blur-sm rounded-xl p-6 border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 transition-all duration-500 group hover:shadow-lg hover:shadow-[#7DB0D2]/10">
                <h3 className="text-[#FDF9D2] text-lg font-semibold tracking-wide mb-4">
                  Contextual Awareness
                </h3>
                <p className="text-[#FDF9D2]/80 text-sm sm:text-base text-center leading-relaxed">
                  Deep understanding across your conversation history
                </p>
              </div>

              <div className="bg-gradient-to-br from-[#282F4B]/40 to-[#4573B9]/20 backdrop-blur-sm rounded-xl p-6 border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 transition-all duration-500 group hover:shadow-lg hover:shadow-[#7DB0D2]/10">
                <h3 className="text-[#FDF9D2] text-lg font-semibold tracking-wide mb-4">
                  Smart AI Assistant
                </h3>
                <p className="text-[#FDF9D2]/80 text-sm sm:text-base text-center leading-relaxed">
                  AI that understands your unique context and preferences
                </p>
              </div>

              <div className="bg-gradient-to-br from-[#282F4B]/40 to-[#4573B9]/20 backdrop-blur-sm rounded-xl p-6 border border-[#7DB0D2]/20 hover:border-[#7DB0D2]/40 transition-all duration-500 group hover:shadow-lg hover:shadow-[#7DB0D2]/10">
                <h3 className="text-[#FDF9D2] text-lg font-semibold tracking-wide mb-4">
                  Seamless Integration
                </h3>
                <p className="text-[#FDF9D2]/80 text-sm sm:text-base text-center leading-relaxed">
                  Connect with your favorite tools and data sources
                </p>
              </div>
            </div>
          </div>

          {/* Bottom Links */}
          <div className="flex justify-center gap-4 text-sm">
            <a 
              href="/" 
              className="text-[#FDF9D2]/60 hover:text-[#FDF9D2]/80 underline"
            >
              Back to TinyCloud
            </a>
            <a 
              href="/privacy" 
              className="text-[#FDF9D2]/60 hover:text-[#FDF9D2]/80 underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MuseLanding; 