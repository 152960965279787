import { Navigate } from 'react-router-dom';

const Redirect = () => {
  // You can store your redirects in an object
  const redirects = {
    '/android': 'https://play.google.com/apps/internaltest/4700727789879218885',
    '/ios': 'https://testflight.apple.com/join/UrtvpNMn',
    // Add more redirects as needed
  };

  // Get the current path
  const path = window.location.pathname;

  // Check if we have a redirect for this path
  const destination = redirects[path];

  if (destination) {
    // For external URLs, we need to use window.location
    window.location.href = destination;
    return null;
  }

  // If no redirect is found, navigate to home or show a 404
  return <Navigate to="/404" replace />;
};

export default Redirect;